import {
  ACCESS_SETTINGS_TO_SALES_DISABLED,
  DISRIBUTOR_ORDERS_POS_PAGE_DISABLED,
  DISTRIBUTOR_ORDERS_PAGE_DISABLED,
  FAQ_PAGE_DISABLED,
  FORECAST_REPORT_DISABLED,
  ORDER_FORMS_ADDITIONS_PAGE_DISABLED,
  ORDER_FORMS_PAGE_DISABLED,
  POS_PAGE_DISABLED,
  PRICE_CALCULATION_PAGE_DISABLED,
  PROFORMAS_PAGE_DISABLED,
  REPORTS_DISABLED,
  SHIPMENT_REQUESTS_PAGE_DISABLED,
  SHIPMENT_REQUESTS_POS_PAGE_DISABLED,
  UPLOAD_PRICES_PAGE_DISABLED,
  UPLOAD_SHIPMENT_SCHEDULE_PAGE_DISABLED,
  WERE_DEVICES_WERE_SOLD_DISABLED,
} from "../../../constants/appConstants";
import { HEALTHCHECK_ENDPOINT } from "../../../constants/endpoints";
import { loc } from "../../../localization";
import { getDisabledStyle } from "../../../utils";
import { IPermissions } from "../../../utils/getPermissionScopes";

export const getMenuItems = (permissions: IPermissions) => [
  {
    key: "reference-books",
    name: loc.menu.referenceBooks,
    url: `/reference-books`,
    iconName: "Dictionary",
    disabled: false,
    links: [
      {
        key: "counterparty",
        name: loc.menu.counterparty,
        url: "/reference-books/counterparty/groups",
        disabled: !permissions.counterpartyPage,
        // underline: true,
      },
      {
        key: "items",
        name: loc.menu.devices,
        iconName: "library-books",
        links: [
          {
            key: "items",
            name: loc.menu.devices.toUpperCase(),
            url: "/reference-books/items/all",
            disabled: !permissions.itemsPage,
          },
          {
            key: "groups-of-items",
            name: loc.menu.devicesGroups,
            url: "/reference-books/items/groups-of-items",
            disabled: false,
          },
          {
            key: "categories-of-items",
            name: loc.menu.devicesCategories,
            url: "/reference-books/items/categories-of-items",
            disabled: false,
          },
          {
            key: "groups-of-products",
            name: loc.menu.productGroups,
            url: "/reference-books/items/groups-of-products",
            disabled: !permissions.groupsOfProdutsPage,
          },
          {
            key: "certificates",
            name: loc.menu.certificates,
            url: "/reference-books/items/certificates",
            disabled: !permissions.certificatesPage,
          },
          {
            key: "trade-marks",
            name: loc.menu.tradeMarks,
            url: "/reference-books/items/trade-marks",
            disabled: !permissions.tradeMarksPage,
          },
        ],
      },
      {
        key: "price-lists",
        name: loc.menu.prices,
        disabled: false,
        links: [
          {
            key: "price-lists",
            name: loc.menu.prices,
            url: "/reference-books/price-lists/price-lists/all",
            disabled: !permissions.priceListsPage,
          },
          {
            key: "names",
            name: loc.menu.pricesNames,
            url: "/reference-books/price-lists/names",
            disabled: !permissions.priceListNamesPage,
          },
          {
            key: "delivery-terms",
            name: loc.menu.delivery,
            url: "/reference-books/price-lists/delivery-terms",
            disabled: !permissions.deliveryTermsPage,
          },
          {
            key: "temporary-forecast-prices",
            name: loc.menu.temporaryForecastPricesPage,
            url: "/reference-books/price-lists/temporary-forecast-prices",
            disabled: !permissions.counterpartyPage,
          },
        ],
      },
      {
        key: "customs-codes",
        name: loc.menu.customsCodes,
        url: "/reference-books/customs-codes",
        disabled: !permissions.customsCodesPage,
      },
      {
        key: "intrastat-codes",
        name: `${loc.menu.customsCodes} (Intrastat)`,
        url: "/reference-books/intrastat-codes",
        disabled: !permissions.customsCodesPage,
        // underline: true,
      },
      {
        key: "countries-of-origin",
        name: loc.menu.countriesOfOrigin,
        url: "/reference-books/countries-of-origin",
        disabled: false,
      },
      {
        key: "currencies",
        name: loc.menu.currencies,
        url: "/reference-books/currencies",
        disabled: !permissions.currenciesPage,
      },
      {
        key: "usd",
        name: loc.menu.exchangeRate,
        url: "/reference-books/exchange-rates/usd",
        disabled: !permissions.exchangeRatesUsdPage,
      },
      {
        key: "responsible-workers",
        name: loc.menu.responsiblePersons,
        url: "/reference-books/responsible-workers",
        disabled: !permissions.responsibleWorkersPage,
      },
      {
        key: "signatures",
        name: loc.menu.signatures,
        url: "/reference-books/signatures",
        disabled: !permissions.signaturesPage,
      },
    ],
  },
  {
    key: "operations",
    name: loc.menu.operations,
    url: "/operations",
    iconName: "DocumentSet",
    disabled: false,
    links: [
      {
        key: "shipments",
        name: loc.menu.shipments,
        url: "/operations/shipments",
        disabled: !permissions.shipmentsPage,
      },
      {
        key: "licenses",
        name: loc.menu.licenses,
        disabled: false,
        links: [
          {
            key: "licenses/all",
            name: loc.menu.workLicenses,
            url: "/operations/licenses/all",
            disabled: !permissions.licensePage,
          },
          {
            key: "gbp",
            name: loc.menu.exchangeRateGBP,
            url: "/reference-books/exchange-rates/gbp",
            disabled: !permissions.exchangeRatesGbpPage,
          },
          {
            key: "lists",
            name: loc.menu.itemsLists,
            url: "/operations/licenses/lists",
            disabled: !permissions.licensePage,
          },
          {
            key: "items/forLicenses",
            name: loc.menu.quickItems,
            url: "/reference-books/items/forLicenses",
            disabled: !permissions.licensePage,
          },
          {
            key: "counterparty/forLicenses",
            name: loc.menu.quickCounterparties,
            url: "/reference-books/counterparty/forLicenses",
            disabled: !permissions.licensePage,
          },
        ],
      },
      {
        key: "order-forms",
        name: loc.menu.orderForms,
        disabled: !permissions.orderFormsPage,
        style: getDisabledStyle(ORDER_FORMS_PAGE_DISABLED),
        links: [
          {
            key: "order-forms/products",
            name: loc.menu.products,
            url: "/operations/order-forms/products",
            disabled: !permissions.orderFormsPage,
          },
          {
            key: "order-forms/spare-parts",
            name: loc.menu.spareParts,
            url: "/operations/order-forms/spare-parts",
            disabled: !permissions.orderFormsSparePage,
          },
          {
            key: "order-forms/pos",
            name: loc.menu.posPage,
            url: "/operations/order-forms/pos",
            disabled: !permissions.posPage,
            style: getDisabledStyle(POS_PAGE_DISABLED),
          },
        ],
      },
      {
        key: "additions",
        name: loc.menu.orderFormsAdditions,
        url: "/operations/order-forms/additions",
        disabled: !permissions.orderFormsAdditionsPage,
        style: getDisabledStyle(ORDER_FORMS_ADDITIONS_PAGE_DISABLED),
      },
      {
        key: "distributor-orders",
        name: loc.menu.distributorOrders,
        disabled: !permissions.ordersPage,
        style: getDisabledStyle(DISTRIBUTOR_ORDERS_PAGE_DISABLED),
        links: [
          {
            key: "distributor-orders/products",
            name: loc.menu.products,
            url: "/operations/distributor-orders/products",
            disabled: !permissions.ordersPage,
          },
          {
            key: "distributor-orders/spare-parts",
            name: loc.menu.spareParts,
            url: "/operations/distributor-orders/spare-parts",
            disabled: !permissions.ordersSparePage,
          },
          {
            key: "distributor-orders/pos",
            name: loc.menu.posPage,
            url: "/operations/distributor-orders/pos",
            disabled: !permissions.distributorOrdersPosPage,
            style: getDisabledStyle(DISRIBUTOR_ORDERS_POS_PAGE_DISABLED),
          },
        ],
      },
      {
        key: "contracts",
        name: loc.menu.contracts,
        url: "/operations/contracts/all",
        disabled: !permissions.contractsPage,
      },
      {
        key: "payments",
        name: loc.menu.payments,
        url: "/operations/payments",
        disabled: !permissions.paymentsPage,
      },
      {
        key: "production-and-quality",
        name: loc.menu.productionAndQuality,
        disabled: false,
        style: getDisabledStyle(SHIPMENT_REQUESTS_PAGE_DISABLED),
        links: [
          {
            key: "shipment-requests",
            name: loc.menu.shipmentRequests,
            url: "/operations/production-and-quality/shipment-requests/devices",
            disabled: !permissions.shipmentRequestsPage,
          },
          {
            key: "shipment-requests-details",
            name: loc.menu.shipmentRequestsDetails,
            url: "/operations/production-and-quality/shipment-requests/details",
            disabled: !permissions.shipmentRequestsPage,
          },
          {
            key: "shipment-requests-pos",
            name: loc.menu.posPage,
            url: "/operations/production-and-quality/shipment-requests-pos",
            style: getDisabledStyle(SHIPMENT_REQUESTS_POS_PAGE_DISABLED),
            disabled: !permissions.shipmentRequestsPage,
          },
          {
            key: "proformas",
            name: loc.menu.proformas,
            url: "/operations/production-and-quality/proformas",
            style: getDisabledStyle(PROFORMAS_PAGE_DISABLED),
            disabled: PROFORMAS_PAGE_DISABLED,
          },
        ],
      },
      {
        key: "rex-devices",
        name: loc.menu.rexDevices,
        url: "/operations/rex-devices",
        disabled: !permissions.rexDevicesPage,
      },
    ],
  },
  {
    key: "price-formation",
    name: loc.menu.priceCalculation,
    url: "/price-formation",
    iconName: "Calculator",
    disabled: PRICE_CALCULATION_PAGE_DISABLED,
    style: getDisabledStyle(PRICE_CALCULATION_PAGE_DISABLED),
    links: [
      {
        key: "price-calculation",
        name: loc.menu.priceCalculation,
        url: "/price-formation/price-calculation",
        disabled: !permissions.priceCalculationPage,
      },
      {
        key: "one-time-prices",
        name: loc.menu.oneTimePrices,
        url: "/price-formation/one-time-prices",
        disabled: !permissions.oneTimePricesPage,
      },
      {
        key: "nomenclature-to-excel",
        name: loc.menu.nomenclatureToExcel,
        url: "/price-formation/nomenclature-to-excel",
        disabled: !permissions.nomenclatureToExcelPage,
      },
      {
        key: "profitability-of-companies",
        name: loc.menu.profitabilityOfCompanies,
        url: "/price-formation/profitability-of-companies",
        disabled: !permissions.profitabilityOfCompaniesPage,
      },
    ],
  },
  {
    key: "reports",
    name: loc.menu.reports,
    url: "/reports",
    iconName: "CRMReport",
    disabled: REPORTS_DISABLED,
    style: getDisabledStyle(REPORTS_DISABLED),
    links: [
      {
        key: "shipments-to-buyers",
        name: loc.menu.shipmentsToBuyers,
        url: "/reports/shipments-to-buyers",
        disabled: !permissions.reports.shipmentsToBuyers,
      },
      {
        key: "debts",
        name: loc.menu.debts,
        disabled: !permissions.reports.debts,
        links: [
          {
            key: "debts-to-be-payed",
            name: loc.menu.debtsToBePayed,
            url: "/reports/debts/debts-to-be-payed",
            disabled: !permissions.reports.debts,
          },
          {
            key: "debts-and-overdue-debts",
            name: loc.menu.debtsAndOverdueDebts,
            url: "/reports/debts/debts-and-overdue-debts",
            disabled: !permissions.reports.debts,
          },
        ],
      },
      {
        key: "buyer-card",
        name: loc.menu.buyerCard,
        url: "/reports/buyer-card",
        disabled: !permissions.reports.buyerCard,
      },
      {
        key: "targets",
        name: loc.menu.targets,
        disabled: !permissions.reports.targets,
        links: [
          {
            key: "enter-targets",
            name: loc.menu.enterTargets,
            url: "/reports/targets/enter-targets",
            disabled: !permissions.reports.enterTargets,
          },
          {
            key: "calculation-of-values",
            name: loc.menu.calculationOfValues,
            url: "/reports/targets/calculation-of-values",
            disabled: !permissions.reports.calculationOfValues,
          },
        ],
      },
      {
        key: "quotas",
        name: loc.menu.quotas,
        disabled: !permissions.reports.quota,
        links: [
          {
            key: "cash-quota",
            name: loc.menu.cashQuota,
            url: "/reports/quotas/cash-quota",
            disabled: !permissions.reports.cashQuota,
          },
          {
            key: "product-quota",
            name: loc.menu.productQuota,
            disabled: !permissions.reports.productQuota,
            links: [
              {
                key: "quota-fullfillment",
                name: loc.menu.quotaFulfillmentForPeriod,
                url: "/reports/quotas/product-quota/quota-fullfillment",
              },
              {
                key: "formation-of-groups",
                name: loc.menu.formationOfGroups,
                url: "/reports/quotas/product-quota/formation-of-groups",
              },
            ],
          },
        ],
      },
      {
        key: "report-on-the-responsible",
        name: loc.menu.reportOnTheResponsible,
        url: "/reports/report-on-the-responsible",
        disabled: !permissions.reports.reportOnTheResponsible,
      },
      {
        key: "execution-of-orders",
        name: loc.menu.executionOfOrders,
        url: "/reports/execution-of-orders",
        disabled: !permissions.reports.executionOfOrders,
      },
      {
        key: "abc-analysis",
        name: loc.menu.abcAnalysis,
        url: "/reports/abc-analysis",
        disabled: !permissions.reports.abcAnalysis,
      },
      {
        key: "queries",
        name: loc.menu.queries,
        disabled: false,
        links: [
          {
            key: "were-the-devices-were-sold",
            name: loc.menu.wereTheDevicesWereSold,
            url: "/reports/queries/were-the-devices-were-sold",
            disabled: !permissions.reports.wereTheDevicesWereSold,
            style: getDisabledStyle(WERE_DEVICES_WERE_SOLD_DISABLED),
          },
          {
            key: "shipment-amounts-of-new-products",
            name: loc.menu.shipmentAmountsOfNewProducts,
            url: "/reports/queries/shipment-amounts-of-new-products",
            disabled: !permissions.reports.shipmentAmountsOfNewProducts,
          },
          {
            key: "finding-device-numbers",
            name: loc.menu.findingDeviceNumbers,
            url: "/reports/queries/finding-device-numbers",
            disabled: !permissions.reports.findingDeviceNumbers,
          },
        ],
      },
      {
        key: "detailed-report",
        name: loc.menu.detailedReport,
        url: "/reports/detailed-report",
        disabled: !permissions.reports.detailedReport,
      },
      {
        key: "forecast-of-shipment",
        name: loc.menu.forecast,
        url: "/reports/forecast-of-shipment",
        disabled: !permissions.reports.forecast,
      },
      {
        key: "average-weighted-warkup",
        name: loc.menu.averageWeightedMarkup,
        url: "/reports/average-weighted-warkup",
        disabled: !permissions.reports.averageWeightedMarkup,
      },
      {
        key: "statistics",
        name: loc.menu.statistics,
        disabled: !permissions.reports.statistics,
        links: [
          {
            key: "assortiment-analysis",
            name: loc.menu.assortimentAnalysis,
            url: "/reports/statistics/assortiment-analysis",
            disabled: !permissions.reports.assortimentAnalysis,
          },
          {
            key: "sales-in-dynamics",
            name: loc.menu.salesInDynamics,
            url: "/reports/statistics/sales-in-dynamics",
            disabled: false,
          },
        ],
      },
      {
        key: "shipping-schedule",
        name: loc.menu.shippingSchedule,
        url: "/reports/shipping-schedule",
        disabled: !permissions.reports.shippingSchedule,
      },
      {
        key: "execution-of-order-by-counterparties",
        name: loc.menu.executionOfOrdersByCounterparty,
        url: "/reports/execution-of-order-by-counterparties",
        disabled: !permissions.reports.executionOfOrdersByCounterparty,
      },
      {
        key: "license-devices",
        name: loc.menu.licenseDevices,
        url: "/reports/license-devices",
        disabled: !permissions.reports.licenseDevices,
      },
      {
        key: "serial-numbers-of-counterparties",
        name: loc.menu.serialNumbers,
        url: "/reports/serial-numbers-of-counterparties",
        disabled: !permissions.reports.serialNumbers,
      },
      {
        key: "device-sales-by-month",
        name: loc.menu.deviceSalesByMonth,
        url: "/reports/device-sales-by-month",
        disabled: false,
      },
      {
        key: "average-delivery-time",
        name: loc.menu.averageDeliveryTimeReport,
        url: "/reports/average-delivery-time",
        disabled: false,
      },
      {
        key: "internal-reports",
        name: loc.menu.internalReports,
        disabled: !permissions.reports.internalReports,
        links: [
          {
            key: "internal-prices-report",
            name: loc.menu.internalPricesReport,
            url: "/reports/internal-reports/prices-report",
            disabled: !permissions.reports.internalReports,
          },
          {
            key: "internal-shipments-report",
            name: loc.menu.internalShipmentsReport,
            url: "/reports/internal-reports/shipments-report",
            disabled: !permissions.reports.internalReports,
          },
          {
            key: "internal-shipments-full-report",
            name: loc.menu.internalShipmentsFullReport,
            url: "/reports/internal-reports/shipments-full-report",
            disabled: !permissions.reports.internalReports,
          },
        ],
      },
      {
        key: "forecast-report",
        name: loc.menu.forecastReport,
        url: "/reports/forecast-report",
        disabled: !permissions.reports.forecastReport,
        style: getDisabledStyle(FORECAST_REPORT_DISABLED),
      },
      {
        key: "yukon",
        name: loc.menu.yukon,
        disabled: !permissions.reports.yukon,
        links: [
          {
            key: "yukon-license-eu001",
            name: loc.menu.yukonLicenseEu001,
            url: "/reports/yukon-license-eu001",
            disabled: !permissions.reports.yukonLicenseEu001,
          },
          {
            key: "yukon-shipment-report",
            name: loc.menu.yukonShipmentReport,
            url: "/reports/yukon-shipment-report",
            disabled: false,
          },
          {
            key: "yukon-shipped-licenses-report",
            name: loc.menu.shippedLicensesReport,
            url: "/reports/yukon-shipped-licenses-report",
            disabled: !permissions.reports.shippedLicensesReport,
          },
          {
            key: "yukon-intrastat",
            name: loc.menu.intrastat,
            url: "/reports/yukon-intrastat",
            disabled: false,
          },
        ],
      },
      {
        key: "polaris",
        name: "Polaris",
        disabled: !permissions.reports.polaris,
        links: [
          {
            key: "polaris-accounting-report",
            name: loc.menu.accountingReport,
            url: "/reports/polaris-accounting-report",
            disabled: false,
          },
          {
            key: "polaris-purchase-report",
            name: loc.menu.purchaseReport,
            url: "/reports/polaris-purchase-report",
            disabled: false,
          },
        ],
      },
      {
        key: "pulsar",
        name: "Pulsar",
        disabled: !permissions.reports.pulsar,
        links: [
          {
            key: "pulsar-intrastat",
            name: loc.menu.intrastat,
            url: "/reports/pulsar-intrastat",
            disabled: false,
          },
        ],
      },
    ],
  },
  {
    key: "service",
    name: loc.menu.service,
    url: "/service",
    iconName: "ServerProcesses",
    disabled: false,
    links: [
      {
        key: "upload-prices",
        name: loc.menu.uploadPricesPage,
        url: "/service/upload-prices",
        disabled: !permissions.uploadPrices,
        style: getDisabledStyle(UPLOAD_PRICES_PAGE_DISABLED),
      },
      {
        key: "price-change-notifications",
        name: loc.menu.priceChangeNotifications,
        url: "/service/price-change-notifications",
        disabled: !permissions.priceChangeNotifications,
      },
      {
        key: "upload-shipment-schedule",
        name: loc.menu.uploadShipmentSchedule,
        url: "/service/upload-shipment-schedule",
        disabled: !permissions.uploadShipmentSchedulePage,
        style: getDisabledStyle(UPLOAD_SHIPMENT_SCHEDULE_PAGE_DISABLED),
      },
      {
        key: "notifications",
        name: loc.menu.notifications,
        url: "/service/notifications",
        disabled: !permissions.notificationsPage,
      },
      {
        key: "shipment-control-log",
        name: loc.menu.shipmentControlLog,
        url: "/service/shipment-control-log",
        disabled: false,
      },
      {
        key: "history",
        name: loc.menu.history,
        disabled: false,
        links: [
          {
            key: "history-of-shipments",
            name: loc.menu.historyOfShipments,
            url: `/service/history-of-shipments`,
            disabled: !permissions.historyPage,
          },
          {
            key: "history-of-items",
            name: loc.menu.historyOfItems,
            url: `/service/history-of-items`,
            disabled: !permissions.historyPage,
          },
          {
            key: "history-of-intrastat-codes",
            name: loc.menu.historyOfIntrastatCodes,
            url: `/service/history-of-intrastat-codes`,
            disabled: !permissions.historyPage,
          },
          {
            key: "history-of-counterparties",
            name: loc.menu.historyOfCounterparties,
            url: `/service/history-of-counterparties`,
            disabled: !permissions.historyPage,
          },
        ],
      },
      {
        key: "access-settings-to-sales",
        name: loc.menu.accessSettingsToSales,
        url: "/service/access-settings-to-sales",
        disabled: !permissions.accessSettingsToSales,
        style: getDisabledStyle(ACCESS_SETTINGS_TO_SALES_DISABLED),
      },
      {
        key: "access-to-portal",
        name: loc.menu.accessToPortal,
        url: "/service/access-to-portal",
        disabled: !permissions.accessToPortalPage,
      },
      {
        key: "healthcheck",
        name: "Healthcheck",
        url: HEALTHCHECK_ENDPOINT,
        disabled: !permissions.healthcheckLink,
        newTab: true,
      },
    ],
  },
  {
    key: "faq",
    name: loc.menu.faq,
    url: "/faq",
    iconName: "ReceiptTentative",
    disabled: FAQ_PAGE_DISABLED,
    style: getDisabledStyle(FAQ_PAGE_DISABLED),
  },
];
