import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { isEqual, isNull, last, map } from "lodash-es";
import jwt_decode from "jwt-decode";
import { getUnixTime, fromUnixTime } from "date-fns";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { SignalRNotificationType } from "../constants/enums";
import { AuthContext } from "./authContext";
import { startTimer } from "../utils";

export interface Props {
  children: JSX.Element | JSX.Element[];
}

interface ICheckUpdatesContext {
  haveUpdates: boolean;
  connection: any;
}

export interface ISignalRNotificationData {
  metadata: {
    correlationId: string;
    isAuthenticated: boolean;
    isSystemAccount: boolean;
    occurredAt: Date;
    userId: string;
  };
  payload: {
    id_otgr: number;
    blok?: string;
    pr?: string;
    n_rasp?: number;
    id_prof?: number;
  };
}

export interface INotificationData {
  type: SignalRNotificationType;
  data: ISignalRNotificationData;
}

export const CheckUpdatesContext = createContext<ICheckUpdatesContext>({
  haveUpdates: false,
  connection: null,
});

export const CheckUpdatesContextProvider = (props: Props) => {
  const { requestAccessToken } = useContext(AuthContext);
  const [connection, setConnection] = useState<any>(null);
  const [haveUpdates, setHaveUpdates] = useState<boolean>(false);

  const checkToken = async () => {
    const token = await requestAccessToken();
    const timeToExpire: boolean = (jwt_decode(token) as any).exp - getUnixTime(new Date()) < 300;
    console.log("Token expire time: ", fromUnixTime((jwt_decode(token) as any).exp));
    if (timeToExpire && connection) {
      connection.stop().finally(connectToSignalR);
    }
    startTimer(checkToken, 300);
  };

  const checkUpdates = () => {
    axios
      .get(window.location.origin, {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        const storageHashes = sessionStorage.getItem("hashes");
        const currentHashes = storageHashes ? JSON.parse(storageHashes) : null;
        const hashes = map(res.data.split("/static/js/").slice(1), (item) =>
          last(item.substring(0, item.indexOf(".chunk.js")).split(".")),
        );
        if (!isEqual(currentHashes, hashes)) {
          sessionStorage.setItem("hashes", JSON.stringify(hashes));
          if (!isNull(currentHashes)) {
            setHaveUpdates(true);
          }
        }
        startTimer(checkUpdates, 300);
      })
      .catch((err) => console.log(err));
  };

  const connectToSignalR = async () => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL}/notification-hub`, {
        accessTokenFactory: async () => {
          const token = await requestAccessToken();
          return token;
        },
      })
      .withAutomaticReconnect()
      .build();
    // if (token && token !== tokenInfo?.token) {
    // 	const exp: any = (jwt_decode(token) as any).exp;
    // 	setTokenInfo({ token, exp: fromUnixTime(exp) });
    // };
    setConnection(newConnection);
  };

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => console.log("Signal R connected!"))
        .catch((e: any) => console.log("Signal R connection failed: ", e));
      connection.onclose((err: any) => {
        console.log("Onclose error: ", err);
        connectToSignalR();
      });
    }
    return () => connection && connection.stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  useEffect(() => {
    checkUpdates();
    checkToken();
    connectToSignalR();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CheckUpdatesContext.Provider value={{ haveUpdates, connection }}>{props.children}</CheckUpdatesContext.Provider>
  );
};
