import { useContext, useEffect, useMemo, useState } from "react";
import { CommandButton, DefaultButton, DetailsRow, FontIcon, GroupHeader, GroupedList, IGroupHeaderProps, PrimaryButton, ScrollablePane, SelectionMode, TextField } from "@fluentui/react";
import { filter, find, isUndefined, map } from "lodash-es";
import { LanguageContext } from "../../../context/languageContext";
import { deleteRequest, getRequest } from "../../../actions";
import { FAQ_ENDPOINT } from "../../../constants/endpoints";
import { getNotification } from "../../../utils";
import { AuthContext } from "../../../context/authContext";
import { CommandbarCommands, Notification } from "../../../constants/enums";
import { PageWrapper } from "../../wrappers/PageWrapper";
import { Loading } from "../../wrappers/LoadingWrapper";
import { ConfirmModal } from "../../common/ConfirmModal";
import { getColumns } from "./config";
import { AddRecordModal } from "./AddRecordModal";
import { EditRecordModal } from "./EditRecordModal";
import "./styles.scss";

interface ITheme {
  fieldName: string,
  id: number;
  isCollapsed: boolean;
  level: number;
  count: number;
  startIndex: number;
  key: string;
  name: string;
};
interface IAnswer {
  name: string,
  id: number;
};

export interface ISelectedRecordInfo extends IAnswer {
  key: string;
}

export const FaqPage = () => {
  const { loc } = useContext(LanguageContext);
  const { permissions } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [answers, setAnswers] = useState<IAnswer[]>([]);
  const [themes, setThemes] = useState<ITheme[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [activeCommand, setActiveCommand] = useState<CommandbarCommands>(CommandbarCommands.None);
  const [selectedRecordId, setSelectedRecordId] = useState<number>(0);
  const [selectedRecordInfo, setSelectedRecordInfo] = useState<ISelectedRecordInfo>({
    name: "",
    key: "",
    id: 0
  });
  const handleModalDismiss = () => setActiveCommand(CommandbarCommands.None);
  const getFaq = () => {
    getRequest(`${FAQ_ENDPOINT}/list`)
      .then((res) => {
        const answers = map(res.data, ({ description, id }) => ({ name: description, id }));
        const themes = map(res.data, ({ theme, id }, i) => ({
          fieldName: theme,
          id: id,
          level: 0,
          isCollapsed: true,
          count: 1,
          startIndex: Number(i),
          key: theme,
          name: theme,
        }))
        setAnswers(answers);
        setThemes(themes);
      })
      .catch((err) => getNotification("Error", err.message, Notification.ERROR))
      .finally(() => setIsLoading(false));
  };

  const deleteRecord = () => {
    setIsLoading(true);
    deleteRequest(`${FAQ_ENDPOINT}/${selectedRecordId}`)
      .then((res) => {
        if (res.status === 200) getNotification("Success", "Record was deleted successfully", Notification.SUCCESS);
        getFaq();
        handleModalDismiss();
      })
      .catch((err) => getNotification("Error", err.message, Notification.ERROR))
      .finally(() => {
        setIsLoading(false);
        setSelectedRecordId(0);
      });
  };

  const getGroupRow = ({ name, id }: ITheme) => {
    const regex = new RegExp(`(${searchValue})`, 'gi');
    const partsOfValue = name?.split(regex);
    return (
      <div className="title-wrapper">
        {searchValue.length ? (
          <div>
            {map(partsOfValue, (part, index) => (
              part.match(regex) ? (
                <span className="selected-part" key={index}>
                  {part}
                </span>
              ) : (
                <span key={index}>{part}</span>
              )
            ))}
          </div>
        ) : <span>{name}</span>}
        <div className="icons-wrapper">
          {permissions.faqPageActionButton && (
            <FontIcon
              iconName="EditSolid12"
              onClick={() => {
                setSelectedRecordInfo({
                  id: id,
                  key: name,
                  name: find(answers, { id: id })?.name || ""
                });
                setActiveCommand(CommandbarCommands.Edit);
              }}
            />
          )}
          {permissions.faqPageActionButton && (
            <FontIcon
              iconName="delete"
              onClick={() => {
                setSelectedRecordId(id)
                setActiveCommand(CommandbarCommands.Delete);
              }}
            />
          )}
        </div>

      </div>
    );
  };

  const onCollapseClick = (group: any) =>
    setThemes(map(
      themes, item => item.id === group.id
        ? ({ ...item, isCollapsed: !item.isCollapsed })
        : item
    ));

  const onRenderCell = (
    nestingDepth?: number,
    item?: IAnswer,
    itemIndex?: number,
    group?: ITheme,
  ) => (
    <div className="group-row">
      <DetailsRow
        columns={getColumns()}
        groupNestingDepth={nestingDepth}
        item={item}
        itemIndex={itemIndex!}
        group={group}
        compact
        selectionMode={SelectionMode.none}
      />
    </div>
  );

  const filteredGroups = useMemo(() => {
    return filter(themes, (item: ITheme) => item.name.toLowerCase().includes(searchValue.toLowerCase()));
  }, [searchValue, themes]);

  useEffect(() => getFaq(), []);

  return (
    <PageWrapper>
      <div className="wrappered-page container faq-page">
        <div className="command-bar">
          <CommandButton
            text={loc.buttons.add}
            iconProps={{ iconName: "add" }}
            onClick={() => setActiveCommand(CommandbarCommands.Add)}
            disabled={!permissions.faqPageActionButton}
          />
        </div>
        <div className="wrappered-page-content">
          <div className="wrappered-page-content__title">
            <h2>{loc.menu.faq}</h2>
          </div>
          <div className="wrappered-page-content__search">
            <TextField
              placeholder={loc.main.search}
              value={searchValue}
              onChange={(_, value) => !isUndefined(value) && setSearchValue(value)}
              onRenderSuffix={() => <FontIcon iconName="ChromeClose" onClick={() => setSearchValue("")} />}
            />
          </div>
          <div className="wrappered-page-content__details">
            <Loading isLoading={false}>
              <ScrollablePane>
                <GroupedList
                  groupProps={{
                    onRenderHeader: (props?: IGroupHeaderProps) => (
                      <GroupHeader
                        {...props}
                        styles={{
                          headerCount: {
                            display: "none"
                          },
                        }}
                        onRenderName={(props: any) => getGroupRow(props.group)}
                        onGroupHeaderClick={onCollapseClick}
                      />
                    )
                  }}
                  compact
                  items={answers}
                  onRenderCell={onRenderCell}
                  groups={filteredGroups}
                  selectionMode={SelectionMode.none}
                />
                {!isLoading && !filteredGroups?.length && <div className="no-data">{loc.warnings.noData}</div>}
              </ScrollablePane>
            </Loading>
          </div>
          {activeCommand === CommandbarCommands.Add && (
            <AddRecordModal addRecord={getFaq} onDismiss={handleModalDismiss} />
          )}

          {activeCommand === CommandbarCommands.Delete && (
            <ConfirmModal
              text={loc.faqPage.panel.titleDeleteRecord}
              leftButton={
                <PrimaryButton
                  text={loc.buttons.delete}
                  onClick={deleteRecord}
                />
              }
              rightButton={
                <DefaultButton
                  text={loc.buttons.cancel}
                  onClick={handleModalDismiss}
                />
              }
              onDismiss={handleModalDismiss}
            />
          )}

          {activeCommand === CommandbarCommands.Edit && (
            <EditRecordModal selectedRecord={selectedRecordInfo} editOrder={getFaq} onDismiss={handleModalDismiss} />
          )}

        </div>
      </div>
    </PageWrapper>
  );
};